import CampaignListLayout from '../component-library/layout/campaignListLayout/CampaignListLayout';
import SectionBackground from '../component-library/SectionBackground';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText/RichText';
import ContentfulImage, { ImageStyleProps } from './Image';
import getLinkHref from './util/getLinkHref';
import { TypeCampaignItem } from '../../../@types/generated';

const CampaignsSection: PageSectionComponent<null> = ({ section }) => {
  const {
    fields: { title, description, references },
  } = section;

  return (
    <SectionBackground>
      <CampaignListLayout
        Title={({ color }) => <RichText document={title} textColor={color} />}
        Description={({ color }) => <RichText document={description} textColor={color} />}
        campaigns={(references as TypeCampaignItem[]).map(
          ({
            fields: {
              campaignName,
              campaignDescription,
              campaignLink,
              campaignCta,
              campaignImage,
              campaignPartnerLogo,
              campaignSubheader,
            },
          }) => ({
            name: campaignName,
            description: campaignDescription,
            link: getLinkHref(campaignLink),
            cta: campaignCta,
            subheader: campaignSubheader,
            CampaignImage: (props: ImageStyleProps) =>
              campaignImage ? <ContentfulImage asset={campaignImage} {...props} /> : null,
            Logo: (props: ImageStyleProps) =>
              campaignPartnerLogo ? (
                <ContentfulImage asset={campaignPartnerLogo} {...props} />
              ) : null,
          }),
        )}
      />
    </SectionBackground>
  );
};

export default CampaignsSection;
